<template>
	<div class="page-container">
		<p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
		<div class="page-desc">
		   可查看员工发起的耗材申请单据，通过审批后操作审批耗材将关联相关单据
		 </div>  
		<el-tabs @tab-click="handleClick" v-model="assetActive">
		   <el-tab-pane v-for="item in assetTabs" :label="item.name" :name="item.id">
			</el-tab-pane> 
		</el-tabs>
		<div class="search-form">
			<el-form ref="form" :inline="true"  :model="search_key" prop="">
				<el-form-item label="">
					<el-input v-model="search_key.receipt_number" placeholder="输入关键字搜索"></el-input>
				</el-form-item>	
			<el-button type="primary" @click="getData()">查询</el-button>
			<!-- <el-button type="primary" @click="handleExport()">导出全部</el-button> -->
			</el-form>
		 </div>
		<!-- <div style="margin-bottom:10px;">
				<el-button size="small" type="danger" plain @click="handleDel()">删除</el-button>
		 </div> -->
		 <el-table
			:data="tableData" 
			 :row-key="getRowKeys"
			  @selection-change="handleSelectionChange"
			style="width: 100%;">
			<el-table-column
			type="selection"
			:reserve-selection="true"
			width="55">
			</el-table-column>
			<el-table-column  type="expand">
				<template  slot-scope="props">
					<el-table	
					style="width: 90%;margin: 0 auto;"
					:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
					:row-style="{height:'40px'}"
					 :cell-style="{padding: '0'}"
					border
					:data="props.row.asset_list">
							<el-table-column label="物料分类" prop="cate_name">
							</el-table-column>
							<el-table-column label="物料名称" prop="name">
							</el-table-column>
							<el-table-column label="品牌" prop="brand">
							</el-table-column>
							<el-table-column label="模型" prop="model">
							</el-table-column>
							<el-table-column :label="props.row.card_name == '耗材采购'?'采购数量':'申请数量'" prop="number">
							</el-table-column> 
							<el-table-column v-if="props.row.card_name == '耗材采购'" label="采购金额" prop="total_prices">
							</el-table-column> 
					</el-table>
				</template>
			</el-table-column>
			<template v-for="item in theadData">
				<el-table-column :key="item.field_name" :label="item.label">
					<template slot-scope="scope">
						<template v-if="item.select_data">
							<span style="color: #F56C6C;">{{item.select_data[scope.row[item.field_name]]['name']}}</span>
						</template>
						<span v-if="!item.select_data">{{scope.row[item.field_name]}}</span>
					</template>
				</el-table-column>
			</template>
			
			<el-table-column
				label="操作"
				min-width="150px"
				max-width="200px"
				>
				<template slot-scope="scope">
						<el-button  size="small" @click="handleApproval(scope.row.id)">审批信息</el-button>
						<!-- <el-button  size="small" type="danger" plain @click="handleDel(scope.row.id)">删除</el-button> -->
						<template v-if="cardName == '耗材领用' && scope.row.status == 1">
							<el-button  size="small" plain @click="handleCommonAdd(scope.row.id,2,'耗材派发单')">耗材派发</el-button>
						</template>
						<template v-if="cardName == '耗材采购' && scope.row.status == 1">
							<el-button  size="small" plain @click="handleCommonAdd(scope.row.id,1,'耗材入库单')">耗材入库</el-button>
						</template>
				</template> 
			</el-table-column>
		 </el-table> 
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		 <ApprovalVue ref="approval"></ApprovalVue>
		  <CommonAddVue ref="childDialog"></CommonAddVue>
		</div>
	</div>
	
</template>
<script>
import ApprovalVue from '../Common/ApprovalDrawer.vue'
import CommonAddVue from "../Common/ConsumableAdd.vue"
export default {
	inject:['reload'],
    components:{
		ApprovalVue,
		CommonAddVue
    },
   data() {
      return {
		  assetActive:0,
		  assetTabs:[],			//资产申请表单
		  search_key:{
			  receipt_number:''
		  },
		  batchStatus:false,
		  cardName:'',	
		  cardType:2,
		  getRowKeys(row){
		      return row.id
		  },
		  tableData:[],
		  currentPage:1,
		  pagesize:10,
		  total:0,
		  theadData:[],
		  assetTheadData:[],
		  addDialogTitle:'',
		  rUrl:{
			  index:'',
			  add:'',
			  del:'',
			  edit:'',
			  export:''
		  },
		  expandStatus:false,
		  storageType:0
      }
   },
   created(){ 
	   this.getTabs()
       if(this.$route.query.activeName) this.activeName =  this.$route.query.activeName
     	if(this.$route.query.status) this.search_key.status = this.$route.query.status
   },
   mounted(){
		
   },
   methods:{
	   getTabs(){
		   this.$api.post('/AssetCard/index',{type:4,apply_type:2},res=>{
		       if (res.code == 200) {
		          this.assetTabs = res.data.list
				  this.assetActive = this.assetTabs[0]['id']
				  this.cardName =  this.assetTabs[0]['name']
				   this.getData()
		       }
		   })
	   },
       getData(){   //表格数据
            this.$api.post('/Staff/assetIndex',{
				page:this.currentPage-1,
				card_id:this.assetActive,
				limit:this.pagesize
			},res=>{
               if (res.code == 200) {
                   this.tableData =  res.data.list??[]
				   this.theadData =  res.data.thead
				   this.assetTheadData =  res.data.asset_thead
                   this.total =  res.data.count
               }
           })
       },
	   handleCommonAdd(staff_storage_id,storage_type,card_name){
		   let addUrl = '/CseDist/add' 	//耗材派发
		   if(card_name == '耗材入库单'){
			   addUrl =  '/CseStorage/add'
			   let list = [];
			   this.tableData.forEach((item,index)=>{
					if(item.id == staff_storage_id){
						item.asset_list.forEach((assetItem,assetIndex)=>{
							list.push(assetItem) 
						})
					}
			   })
			   this.$refs.childDialog.tableData = list
		   }
		   this.$nextTick(()=>{
		   	this.$refs.childDialog.cardName = card_name
		   	this.$refs.childDialog.addUrl  = addUrl
		   	this.$refs.childDialog.storageType = storage_type
			this.$refs.childDialog.staffStorageId =  staff_storage_id
		   	this.$refs.childDialog.handleAdd() 
		   })
	   },
	  
		handleClick(tab, event){
			this.assetTabs.forEach((item,index)=>{
				if(item.id == this.assetActive){
					this.cardName = item.name
				}
			})
			this.currentPage = 1
			this.pagesize=10
			this.theadData=[],
			this.assetTheadData=[],
			this.getData()
		},
         //监听表格多选框
       handleSelectionChange(val){
           this.multipleSelection  = []
           var list = val;
            list.forEach(item=>{
                this.multipleSelection.push(item.id )
            })
			if(this.multipleSelection.length > 0){
				this.batchStatus = true
			}else{
				this.batchStatus = false
			}
			console.log('multi:',this.multipleSelection)
       },
		handleApproval(id){
			// this.$refs.approval.storageType = this.storageType
			this.$refs.approval.approvelType = 4
			this.$refs.approval.handleApproval(id,this.storageType,this.cardName)
		},
		handleExport(){
			var id = this.multipleSelection?this.multipleSelection.join(','):''
			location.href = '/api/admin'+this.rUrl.export+'/?id='+id
		},
		
        getCurrPage(val){   //获取当前页
            this.currentPage =  val
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
            this.getData()
        },
		
		handleDel(id=0){
			let ids = []
			if(id > 0){
				ids.push(id)
			}else{
				ids =  this.multipleSelection
			}
			this.$alert("确定删除选中数据吗？", "删除提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.delete('Staff/del', {
							id:ids
						}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
		handleCheck(id){
			this.$alert("请确定资产已维修完毕，验收后资产恢复闲置状态？", "验收提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.put('AssetMaintain/check', {
							id:id
						}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		}
   }

}
</script>
<style lang="scss">

  .bg-purple {
      padding:10px;
    background:  #d9ecff;
  }
  .bg-purple-light {
    background: #d9ecff;
  }

</style>